@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,700&display=swap');

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.08em;
  line-height: 2rem;
  font-size: 1.125rem;
  background-color: #fdfdfd;
}

.bubbleall {
  position: absolute;
  animation-name: bubble-animation;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.bubble1 {
  animation-duration: 20s;
}

.bubble2 {
  animation-duration: 22s;
}

.bubble3 {
  animation-duration: 16s;
}

.bubble4 {
  animation-duration: 16s;
}


.bubbleimg {
  animation: bubbleimg 2s ease-out;
  animation-iteration-count: 1;
}

.bubbleimg2 {
  animation: bubbleimg2 5s ease-out;
  animation-iteration-count: 1;
}

.bubbleimg3 {
  animation: bubbleimg3 5s ease-out;
  animation-iteration-count: 1;
}

.bubbleimg4 {
  animation: bubbleimg4 3s ease-out;
  animation-iteration-count: 1;
}

@keyframes bubble-animation {
  0% {
    transform: translateY(0);
    opacity: 20%;
  }

  30% {
    opacity: 60%;
  }

  100% {
    transform: translateY(-600px);
    opacity: 20%;
  }
}

@keyframes bubbleimg {
  0% {
    opacity: 0%;
    scale: 50%;
  }

  80% {
    opacity: 80%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes bubbleimg2 {
  0% {
    opacity: 0%;
    transform: scale(0.8);
  }

  30% {
    opacity: 0%;

  }

  70% {
    opacity: 80%;
    transform: scale(1);
  }

  100% {
    opacity: 100%;
  }
}

@keyframes bubbleimg3 {
  0% {
    opacity: 0%;
    transform: scale(0.9);
  }

  50% {
    opacity: 0%;
  }

  70% {
    opacity: 80%;
  }

  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes bubbleimg4 {
  0% {
    opacity: 0%;
    transform: scale(0.9);
  }

  20% {
    opacity: 0%;

  }

  80% {
    opacity: 60%;

  }

  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

h1 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  color: #5196CF;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2.5rem;
  font-size: 1.125rem;
}

input::placeholder {
  color: #2a79b6;
  font-weight: 300;
}

input:focus::placeholder {
  opacity: 0;
}

input:disabled::placeholder {
  color: rgb(243, 243, 243);
  ;
}

input:disabled {
  background: rgb(243, 243, 243);
}

label {
  display: block;
  padding: 0.2rem 3rem;
  border: 1px solid rgb(5, 144, 243);
  width: fit-content;
  margin: 1rem auto;
  border-radius: 6px;
  cursor: pointer;
}

label:hover {
  background-color: #0ea5e9;
  color: white;
}


.genderradio {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.genderradio:focus {
  background-color: #0ea5e9;
  color: white;

}

.customerInfo {
  color: #5196CF;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2.5rem;
  font-size: 1.125rem;
}

.showcustomerInfo span {
  color: #5196CF;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2.5rem;
  font-size: 1.125rem;
  margin-left: 0.2rem;
}

.showcustomerInfo p {
  color: #5196CF;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 2.5rem;
  font-size: 1.125rem;
}

.bgwave {
  background-size: cover;
  background-image: url("../public/images/welcomepage/welcomepagebg.png");
  height: 26vh;
  z-index: -999;
}